import React from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Toolbar, Typography } from '@mui/material';

/* global APP_VERSION */

const CustomDrawer = ({ isOpen, closeFunc, children }) => {
    const theme = useTheme();
    return (<Drawer open={isOpen}
        variant="persistent"
        transitionDuration={{ enter: theme.transitions.duration.enteringScreen, exit: theme.transitions.duration.leavingScreen}}
        sx={(theme) => ({
            position: "relative",
            width: theme.spacing(50),
            [theme.breakpoints.down("xl")]: {width: theme.spacing(40)},
            '& .MuiDrawer-paper': {
                width: theme.spacing(50),
                [theme.breakpoints.down("xl")]: {width: theme.spacing(40)},
                boxSizing: 'border-box',
                overflowY: "visible",
                height: "100%",
                '& .drawer-content': {
                    height: '100%',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", 
                }
            },
            '& h3': {
                margin: "20px 30px 10px 30px",
                [theme.breakpoints.down("xl")]: {margin: "20px 20px 10px 20px"},
            },
            "& .drawer-container": {
                padding: "10px 30px",
                [theme.breakpoints.down("xl")]: {padding: "10px 20px"},
            },
            "& .MuiListItemButton-root": {
                padding: "5px 30px",
                [theme.breakpoints.down("xl")]: {padding: "5px 20px"},
            }
        })}
    >
        <Toolbar />
        {closeFunc && <Box className="settings-arrow" onClick={closeFunc} />}
        <Box className="drawer-content">
            <Box>
                {children}
            </Box>
            <Box className="d-flex-center">
                <Typography variant="caption">© TEKTELIC Communications Inc. | KONA Atlas v{APP_VERSION}</Typography>
            </Box>
        </Box>
    </Drawer>)
}

export default CustomDrawer;